// ------------------------------------------------------------------
// TEAM
// ------------------------------------------------------------------

.team-member {
    h1 {
        margin-bottom: 0.2em;
    }
    h2 {
        color: $color-primary;
    }
    img {
        border-radius: 50%;
        margin-bottom: 1.5em;
	}
	&__info {
		padding: 50px 25px;
		background: $color-secondary;
		border-radius: 15px;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
		@include breakpoint-min(md) {
			padding: 50px;
		}

		ul {
			display: inline-block;
			margin: 0 auto;
			text-align: left;
		}
		li {
			font-size: 0.9em;
			font-weight: 700;
			color: $color-white;
			line-height: 2em;
			@include breakpoint-min(md) {
				font-size: 1em;
			}
		}
		i {
			font-weight: 400;
			color: $color-primary;
			margin-right: 10px;
		}
		a {
			color: $color-white;
			text-decoration: none;

			&:hover {
				color: lighten( $color-secondary, 50% );
			}
		}
	}
}