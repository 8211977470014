// ------------------------------------------------------------------
// CTA
// ------------------------------------------------------------------

%heading {
    font-size: px-to-em(24px);
    margin-bottom: 2em;
    @include breakpoint-min(md) {
        font-size: px-to-em(28px);
    }
}
%phone {
    font-size: px-to-em(24px);
    font-weight: 700;
    @include breakpoint-min(md) {
        font-size: px-to-em(28px);
    }

    i {
        margin-right: 10px;
    }
}

.cta {
    text-align: center;
    border-radius: 15px;
    overflow: hidden;

	a {
		text-decoration: none;
	}
    &.primary {
        background: $color-primary;
        padding: 50px;
        @include breakpoint-min(md) {
            padding: 75px 25px;;
        }

        h5 {
            @extend %heading;
            color: #641327;
        }
        span {
            @extend %phone;
            color: $color-white;

            i {
                color: lighten($color-primary, 15%);
            }
        }
    }
    &.white {
        background: $color-white;
        padding: 50px;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
        @include breakpoint-min(md) {
            padding: 75px 25px;;
        }

        h5 {
            @extend %heading;
            color: $color-spot;
        }
        span {
            @extend %phone;
            color: $color-secondary;

            i {
                color: $color-primary;
            }
        }
    }
    &.side-by-side {
        @include flexbox;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);

        .left {
            background: $color-primary;
            padding: 25px;
            @include breakpoint-min(md) {
                padding: 50px 0;
                width: 50%; 
            }
        }
        .right {
            background: $color-white;
            padding: 25px;
            @include breakpoint-min(md) {
                width: 50%;
                @include flexbox;
                align-items: center;
                justify-content: center;
            }
        }
        h5 {
            @extend %heading;
            color: $color-white;
            margin-bottom: 0;
        }
        span {
            @extend %phone;
            color: $color-secondary;

            i {
                color: $color-primary;
            }
        }
    }
}