// ------------------------------------------------------------------
// SECTIONS
// ------------------------------------------------------------------

.full-width {
	width: 100%;
	&.pad-full {
		padding: ($padding-section / 2) 0;
		@include breakpoint-min(sm) {
			padding: $padding-section 0;
		}
	}
	&.pad-top {
		padding: ($padding-section / 2) 0 0;
		@include breakpoint-min(sm) {
			padding: $padding-section 0 0;
		}
	}
	&.pad-bottom {
		padding: 0 0 ($padding-section / 2);
		@include breakpoint-min(sm) {
			padding: 0 0 $padding-section;
		}
	}
	&.pad-half {
		padding: ($padding-section / 2) 0;
	}
}