// ------------------------------------------------------------------
// GENERAL
// ------------------------------------------------------------------

body {
	font-family: $font-family;
	font-size: $font-size-base;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	max-width: 100%;
	height: auto;

	&.border-radius {
		border-radius: 15px;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
	}
}

.container--sm {
	max-width: $container-sm;
}

.container--md {
	max-width: $container-md;
}

.container--lg {
	max-width: $container-lg;
}

// ------------------------------------------------------------------
// CLEARFIX
// ------------------------------------------------------------------

.cf:before,
.cf:after {
	content: " ";
	display: table;
}
.cf:after {
	clear: both;
}
.clear {
	clear: both;
}

// ------------------------------------------------------------------
// FLEX COLUMNS
// ------------------------------------------------------------------

.flex {
	display: block;
	@include breakpoint-min(md) {
		@include flexbox;
		align-items: center;
	}
}