// ------------------------------------------------------------------
// HOME
// ------------------------------------------------------------------

.visual {
    .row {
        position: relative;
    }
}

.hero-content {
	padding: 50px 15px;
	text-align: center;
    @include breakpoint-min(lg) {
		padding-right: 100px;
		text-align: left;
    }
}

.hero-image {
    position: relative;
    padding-left: 0;
    border-radius: 15px;
    box-shadow: 0 0 50px rgba(0, 0, 0, .2);
    overflow: hidden;
    position: relative;
    z-index: 99;

    img {
        margin-bottom: -3px;
    }
    .slick-dots {
        position: absolute;
        bottom: 15px;
        left: 15px;

        li {
            display: inline-block;
            margin-right: 5px;

            &.slick-active button {
                background: $color-primary;
            }
        }
        button[type="button"] {
            display: block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 0;
            text-indent: -9999px;
            -webkit-appearance: none;
            outline: none;
            cursor: pointer;
        }
    }
    &:before {
        content: "+";
        font-size: 2em;
        color: $color-primary;
        position: absolute;
        right: 50px;
        bottom: -50px;
    }
}

.bg {
    @include breakpoint-min(md) {
        position: absolute;
        width: 100%;
        height: 200%;
        left: 65%;
        bottom: -120px;
        background: url( ../img/bg-visual.svg ) no-repeat;
        background-size: contain;
        z-index: -1;
    }
}