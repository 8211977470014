// ------------------------------------------------------------------
// REM FONT SIZE
// ------------------------------------------------------------------

@mixin font-size($size) {
	font-size: $size;
	font-size: rem($size);
}

// ------------------------------------------------------------------
// TRANSITION
// ------------------------------------------------------------------

@mixin transition($property) {
	-webkit-transition: all $property ease-in-out;
	-moz-transition: all $property ease-in-out;
	-ms-transition: all $property ease-in-out;
	-o-transition: all $property ease-in-out;
	transition: all $property ease-in-out;
}

// ------------------------------------------------------------------
// OPACITY
// ------------------------------------------------------------------

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

// ------------------------------------------------------------------
// FLEXBOX
// ------------------------------------------------------------------

@mixin flexbox() {
	display: block;
	@include breakpoint-min(md) {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
}

// ------------------------------------------------------------------
// BREAKPOINTS
// ------------------------------------------------------------------

@mixin breakpoint-max($class) {
	@if $class == xs {
		@media (max-width: $breakpoint-xs) { @content; }
	}
	@else if $class == sm {
		@media (max-width: $breakpoint-sm) { @content; }
	}
	@else if $class == md {
		@media (max-width: $breakpoint-md) { @content; }
	}
	@else if $class == lg {
		@media (max-width: $breakpoint-lg) { @content; }
	}
	@else if $class == xl {
		@media (max-width: $breakpoint-xl) { @content; }
	}
	@else {
		@warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
	}
}

@mixin breakpoint-min($class) {
	@if $class == xs {
		@media (min-width: $breakpoint-xs) { @content; }
	}
	@else if $class == sm {
		@media (min-width: $breakpoint-sm) { @content; }
	}
	@else if $class == md {
		@media (min-width: $breakpoint-md) { @content; }
	}
	@else if $class == lg {
		@media (min-width: $breakpoint-lg) { @content; }
	}
	@else if $class == xl {
		@media (min-width: $breakpoint-xl) { @content; }
	}
	@else {
		@warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
	}
}