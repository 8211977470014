// ------------------------------------------------------------------
// HEADINGS
// ------------------------------------------------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 700;
	color: $color-secondary;
	line-height: 1.2em;
	margin-bottom: px-to-em($font-size-base);
}

h1, .h1 {
	font-size: rem($font-size-h2);
	@include breakpoint-min(md) {
		font-size: rem($font-size-h1);
	}
}
h2, .h2 {
	font-size: rem($font-size-h3);
	@include breakpoint-min(md) {
		font-size: rem($font-size-h2);
	}
}
h3, .h3 {
	font-size: rem($font-size-h3);
}
h4, .h4 {
	font-size: rem($font-size-h4);
}
h5, .h5 {
	font-size: rem($font-size-h5);
}
h6, .h6 {
	font-size: rem($font-size-h6);
}

// ------------------------------------------------------------------
// PARAGRAPHS
// ------------------------------------------------------------------

p {
	font-size: rem($font-size-base);
	font-weight: 300;
	color: $color-spot;
	line-height: 1.5em;
	margin-bottom: 1.5em;
}
strong {
	font-weight: bold;
	color: $color-secondary;
}
em {
	font-style: italic;
}
hr {
	display: inline-block;
	margin: 0 0 2em;
	border: 0;
	background: $color-primary;
	width: 20px;
	height: 1px;
}
.table-container {
	overflow-x: auto;
}
table {
	width: 100%;
	@include breakpoint-min(md) {
		table-layout: fixed;
	}

	tr {
		border-bottom: 1px solid #e5e5e5;
	}
	tr:first-of-type td {
		font-weight: 700;
		color: $color-secondary;
	}
	tr:last-of-type {
		border-bottom: 0;
	}
	td {
		padding: 15px 5px;
		font-size: 0.8em;
		font-weight: 400;
		color: $color-spot;
		@include breakpoint-min(md) {
			font-size: 0.85em;
		}
	}
}

// ------------------------------------------------------------------
// LINKS
// ------------------------------------------------------------------

a {
	color: $color-primary;
	@include transition(0.2s);

	&:hover {
		color: darken($color-primary, 15%);
	}
}