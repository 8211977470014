// ------------------------------------------------------------------
// FOOTER
// ------------------------------------------------------------------

footer {
    position: relative;
    padding: 50px 0 0;
    text-align: center;
    @include breakpoint-min(md) {
        text-align: left;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 130px;
        right: 0;
        bottom: 0;
        background: $color-secondary;
        z-index: -1;
    }
    img {
        max-width: 200px;
        height: auto;
        @include breakpoint-min(md) {
            max-width: 200px;
        }
    }
}

.address {
    .address__info {
        padding: 25px;
        @include breakpoint-min(md) {
            padding: 75px;
        }
    }
    .address__info:nth-child(1) {
        border-bottom: 2px solid lighten($color-secondary, 5%);
        margin-bottom: 0;
        @include breakpoint-min(md) {
            border-bottom: 0;
            border-right: 1px solid lighten($color-secondary, 5%);
            text-align: right;
        }
    }
    .address__info:nth-child(2) {
        @include breakpoint-min(md) {
            border-left: 1px solid lighten($color-secondary, 5%);
        }
    }
    i {
        font-size: 1.2em;
        color: $color-primary;
        margin-bottom: 1em;
    }
    h6 {
        color: $color-white;
        margin-bottom: 2em;
    }
    p {
        font-weight: 400;
        line-height: 2em;
        margin-bottom: 0;
    }
}

.signoff {
    text-align: center;
    font-size: px-to-em(13px);
    font-weight: 400;
    color: #667481;
    border-top: 2px solid lighten($color-secondary, 5%);
    padding: 50px 0;
    line-height: 2em;

    a.button {
        display: block;
        margin: 0 auto;
        width: 250px;
    }
    a.social {
        display: block;
        margin: 1.5em auto 2em;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        background: #667481;
		color: $color-secondary;

        &:hover {
            background: $color-white;
            color: $color-secondary;
        }
    }
    span {
        margin: 0 10px;
        color: $color-primary;
    }
    a {
        color: inherit;
        
        &:hover {
            color: $color-white;
        }
    }
}