// ------------------------------------------------------------------
// HEADER
// ------------------------------------------------------------------

header {
    z-index: 9999;
    @include breakpoint-min(lg) {
        padding: 35px 0;
    }

    // Page header
    &.page {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
        @include breakpoint-min(lg) {
            padding: 20px 0;
        }
    }

    .container {
        flex-wrap: wrap;
    }
}

.logo {
    position: absolute;
	top: 90px;
	z-index: 999;
    @include breakpoint-min(lg) {
        position: relative;
        top: 0;
        margin-right: 30px;
    }
    @include breakpoint-min(xl) {
        margin-right: 100px;
    }

    img {
        height: 60px;
		width: auto;
		@include breakpoint-min(lg) {
			height: 80px;
		}
        @include breakpoint-min(xl) {
            height: 90px;
        }
    }
}

.main-nav {
    li {
        display: inline-block;
        margin-right: 20px;

        &.active a:after {
            width: 100%;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    a {
        position: relative;
        display: inline-block;
        font-size: px-to-em(13px);
        font-weight: 700;
        color: $color-secondary;
        text-decoration: none;
        @include breakpoint-min(xl) {
            font-size: px-to-em(14px);
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -8px;
            height: 1px;
            width: 0;
            background: $color-primary;
            @include transition(0.2s);
        }
        &:hover:after {
            width: 100%;
        }
    }
}

.contact {
    margin-left: auto;
    width: auto;
    text-align: center;
    @include breakpoint-max(lg) {
        margin-left: 0;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        padding: 15px 0;
    }

	a {
		text-decoration: none;
	}
    li {
        display: inline-block;
        font-size: px-to-em(15px);
        font-weight: 700;


        i {
            margin-right: 10px;
            font-weight: 400;
            color: $color-primary;
        }
        &.button {
            margin-left: 15px;

            a {
                display: inline-block;
                background: $color-primary;
                height: 45px;
                line-height: 45px;
                padding: 0 15px;
                border-radius: 25px;
                font-size: px-to-em(13px);
                color: $color-white;
                text-decoration: none;
                @include breakpoint-min(md) {
                    padding: 0 25px;
                    font-size: px-to-em(14px);
                }

                i {
                    color: $color-white;
				}
				&:hover {
					background: darken( $color-primary, 5% );
				}
			}
        }
    }
}