// ------------------------------------------------------------------
// BUTTONS
// ------------------------------------------------------------------

a.button {
	position: relative;
	display: inline-block;
	margin: 1em auto 0;
	height: 45px;
	line-height: 42px;
	padding: 0 35px;
	font-size: 0.8em;
	font-weight: 700;
	text-decoration: none;
	border-radius: 25px;
	
	&.plus:before {
		content: "+";
		margin-right: 10px;
	}
	&.primary {
		background: $color-primary;
		border: 1px solid $color-primary;
		color: $color-white;
		&:hover {
			background: darken($color-primary, 5%);
			border: 1px solid darken($color-primary, 5%);
		}
		&.plus:before {
			color: $color-white;
		}
	}
	&.secondary {
		background: $color-secondary;
		border: 1px solid $color-secondary;
		color: $color-white;
		&:hover {
			background: darken($color-secondary, 10%);
			border: 1px solid darken($color-secondary, 10%);
		}
		&.plus:before {
			color: $color-white;
		}
	}
	&.ghost-primary {
		border: 2px solid $color-primary;
		color: $color-primary;
		&:hover {
			background: $color-primary;
			color: $color-white;
		}
		&.plus:before {
			color: $color-secondary;
		}
	}
	&.ghost-secondary {
		border: 2px solid $color-secondary;
		color: $color-secondary;
		&:hover {
			background: $color-secondary;
			color: $color-white;
		}
		&.plus:before {
			color: $color-primary;
		}
	}
}