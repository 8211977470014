// ------------------------------------------------------------------
// MOBILE NAV
// ------------------------------------------------------------------

.slicknav_menu {
	display: none;
	font-size: 16px;
	order: 2;
}
.slicknav_menu:before, .slicknav_menu:after {content: " "; display: table;}
.slicknav_menu:after {clear: both;}
.slicknav_menu .slicknav_menutxt {display: none;}
.slicknav_menu .slicknav_icon-bar {background-color: $color-white; display: block; height: 3px; width: 20px;}
.slicknav_menu .slicknav_icon {float: left;}
.slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {margin-top: 4px;}
.slicknav_btn {cursor: pointer; display: block; float: right; line-height: 1.125em; padding: 12px; margin: 20px 0; background: $color-primary; vertical-align: middle; text-decoration: none; border-radius: 8px;}
.slicknav_nav {clear: both; color: #333; font-size: 1.2em; margin: 0; padding: 20px 0 0; z-index: 998;}
.slicknav_nav ul, .slicknav_nav li {display: block;}
.slicknav_nav ul {margin: 0; padding: 0;}
.slicknav_nav li {margin: 0; border-bottom: 1px solid #e5e5e5; text-align: center; font-size: 0.8em;}
.slicknav_nav li:first-of-type {border-top: 1px solid #e5e5e5;}
.slicknav_nav li:last-of-type {border-bottom: 0;}
.slicknav_nav li a {position: relative; padding: 10px 0 10px 0; display: block; color: #333; text-decoration: none; font-weight: 400; transition: all 0.25s ease-in-out;}
.slicknav_nav li a:hover {color: $color-primary;}
li.slicknav_parent > ul li a {padding-left: 10px; color: #666;}
.slicknav_nav .slicknav_item a, .slicknav_nav .slicknav_parent-link a {display: inline;}
.slicknav_nav, .slicknav_nav ul {list-style: none outside none; overflow: hidden;}
.slicknav_menu .nav-logo {display: none;}
.slicknav_logo {display: none;}
.slicknav_arrow {position: absolute; font-family: 'FontAwesome'; margin-left: 8px; font-size: 0.7em; top: 32%;}
.slicknav_nav > a {border: 1px solid #31db3c; color: #fff; background: #31db3c; display: block; margin: 1em auto 0; width: 120px; height: 40px; border-radius: 25px; line-height: 38px; font-size: 0.75em; font-weight: 700; text-transform: uppercase; text-decoration: none; text-align: center;}

@include breakpoint-max(lg) {
	.main-nav {display: none;}
    .slicknav_menu {display: block; width: 100%; padding: 0;}
}