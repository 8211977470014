// ------------------------------------------------------------------
// OVERLAYS
// ------------------------------------------------------------------

%overlay {
    content: "";
    position: absolute;
    background: $color-secondary;
    opacity: 0.1;
    width: 300px;
    height: 150px;
    border-radius: 15px;
}

figure {
    position: relative;

    &.overlay-left:after {
        @extend %overlay;
        bottom: -25px;
        left: -25px;
    }
}

// ------------------------------------------------------------------
// TILES
// ------------------------------------------------------------------

.tile {
    background: $color-white; 
    border-radius: 15px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    padding: 30px;
    @include breakpoint-min(md) {
        padding: 50px;
    }

    &.team {
        margin-top: 75px;
        padding: 0 30px 30px 30px;

        img {
            position: relative;
            transform: translateY(-50%);
            border-radius: 50%;
        }
        h2 {
            font-size: 1.2em;
            margin-bottom: 0.2em;
        }
        span {
            display: block;
            color: $color-primary;
            margin-bottom: 1.5em;
        }
    }
    &.location {
        margin-top: 75px;
        padding: 0 30px 30px 30px;
        text-align: center;

        .icon {
            display: inline-block;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            background: $color-primary;
            position: relative;
            transform: translateY(-50%);
            border-radius: 50%;

            i {
                color: $color-white;
            }
		}
		.button {
			display: block;
			@include breakpoint-min(md) {
				display: inline-block;
			}
		}
    }
}

// ------------------------------------------------------------------
// SIDEBAR
// ------------------------------------------------------------------

.sidebar {
    .widget {
        padding: 40px;
        border-radius: 15px;
        background: $color-white;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
        margin-bottom: 1.5em;
    }
    ul {
        margin-bottom: 0;

        li {
            display: block;
			border-bottom: 1px solid #eaeaea;
			
			&.active a,
			&.active a:after {
				color: $color-primary;
			}
            &:last-of-type {
                border-bottom: 0;
            }
        }
        a {
            position: relative;
            display: block;
            color: #6e7b87;
            text-decoration: none;
            padding: 15px 0;
            &:hover,
            &.active {
				color: $color-primary;
				
				&:after {
					color: $color-primary;
				}
            }
            &:after {
                content: "\f105";
                font-family: 'Font Awesome 5 Pro';
                font-size: 0.8em;
                font-weight: 300;
                color: lighten( $color-secondary, 70% );
                position: absolute;
                right: 0;
                top: 50%;
				transform: translateY(-50%);
				@include transition(0.2s);
            }
        }
    }
    a.button {
        width: 100%;
        text-align: center;
    }
}

// ------------------------------------------------------------------
// FAQ
// ------------------------------------------------------------------

.accordeon {
    margin-top: 3em;
}

.accordeon-header {
    position: relative;
    cursor: pointer;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    padding: 15px 40px 15px 15px;
    font-size: 0.875em;
    font-weight: 700;
    color: $color-spot;
    margin-bottom: 0;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
}

.accordeon-header:hover,
.active-header,
.active-header:hover {
    background: $color-spot;
    color: #fff;
}

%arrow {
    font-family: 'Font Awesome 5 Pro';
    font-size: 0.8em;
    font-weight: 400;
    position: absolute;
    top: 12px;
    right: 15px;
}

.accordeon-header:after {
    @extend %arrow;
    content: "\f107";
}

.active-header:after {
    @extend %arrow;
    content: "\f106";
    color: #fff;
}

.accordeon-content {
    display: none;
    padding: 30px 20px;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.accordeon-content p:last-of-type {
    margin-bottom: 0;
}

// ------------------------------------------------------------------
// NEWS
// ------------------------------------------------------------------

.news-item {
	border-radius: 15px;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
	margin-bottom: 2em;
	overflow: hidden;
	@include breakpoint-min(md) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&__image {
		width: 100%;
		height: 300px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-color: $color-secondary;
		@include breakpoint-min(md) {
			width: 25%;
			height: auto;
			align-self: stretch;
		}

		img {
			display: block;
		}
	}
	&__content {
		width: 100%;
		padding: 25px;
		@include breakpoint-min(sm) {
			padding: 50px;
		}
		@include breakpoint-min(md) {
			width: 75%;
			padding: 30px 50px;
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}
	&__date {
		font-size: 0.9em;
		font-weight: 700;
		color: $color-primary;
		margin-bottom: 0.2em;
	}
}

.pagination {
	li {
		display: inline-block;

		&.active a {
			background: $color-primary;
			color: $color-white;
		}
	}
	a {
		display: inline-block;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 3px;
		background: lighten( $color-secondary, 85% );
		font-size: 0.9em;
		color: $color-secondary;
		text-decoration: none;

		&:hover {
			background: $color-primary;
			color: $color-white;
		}
	}
}