// ------------------------------------------------------------------
// SECTION PADDINGS
// ------------------------------------------------------------------

.pf {
	padding: ($padding-section / 2) 0;
	@include breakpoint-min(sm) {
		padding: $padding-section 0;
	}
}
.pt {
	padding: ($padding-section / 2) 0 0;
	@include breakpoint-min(sm) {
		padding: $padding-section 0 0;
	}
}
.pb {
	padding: 0 0 ($padding-section / 2);
	@include breakpoint-min(sm) {
		padding: 0 0 $padding-section;
	}
}
.ph {
	padding: ($padding-section / 2) 0;
}