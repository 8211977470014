// ------------------------------------------------------------------
// WYSIWYG
// ------------------------------------------------------------------

.wysiwyg {
    ul {
        margin-bottom: 1em;
    }

    li {
        font-size: px-to-em($font-size-base);
	    font-weight: 300;
	    color: $color-spot;
	    line-height: 1.5em;
        display: list-item;
        list-style-type: disc;
        margin-left: 1.1em;
    }

    img {
        margin-bottom: -3px;
    }

    figure {
        position: relative;
        margin: 3em 0;

        figcaption {
            position: absolute;
            left: 10px;
            bottom: 10px;
            padding: 15px;
            background: $color-white;
            border-left: 3px solid $color-primary;
            font-size: 0.9em;
            font-weight: 300;
            color: $color-spot;
        }
    }
}